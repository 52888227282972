import React from "react"
import tw from "twin.macro"
import Mapplic from "../Mapplic/Mapplic"

interface Props {
  location?: string
}

const Map: React.FC<Props> = (props) => {
  let focus;
  if (typeof window !== 'undefined') {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    focus = params.get('focus')
  }

  return (
    <article css={[tw`mt-8 border border-lightGray mx-4 max-w-6xl md:mx-auto mt-16`]}>
      <Mapplic styles={tw`min-h-[700px] sm:min-h-[900px]`} showLoading={true} focusOnLoad={focus === 'true' ? true : false} sidebar search />
    </article>
  )
}

export default Map
