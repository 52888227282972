import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import TerminalMapsTitle from "../../components/TerminalMaps/TerminalMapsTitle"
import tw from "twin.macro"
import { TemplateProps } from "../templates"
// import TerminalMapsSearch from '../../components/TerminalMaps/TerminalMapsSearch';
import Map from "../../components/TerminalMaps/Map"
import { SEO } from "../../components/SEO"
import { Image } from "../../components/Image"

const TerminalMaps: React.FC<TemplateProps<any>> = ({ data, location }) => {
  const { title, content, featuredImage, template } = data.wpPage
  const image = getImage(
    featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <Image
          src={image}
          publicURL={featuredImage?.node?.localFile?.publicURL}
          alt=""
          css={[tw`h-80`]}
          objectPosition="50% 90%"
          role="presentation"
        />
        <TerminalMapsTitle title={title} content={content} />
        {/* <TerminalMapsSearch /> */}
        <Map />
      </section>
    </div>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      content
      title
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default TerminalMaps
